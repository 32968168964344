import { Box } from "@mui/material";
import "../css/Styles.css"


export default function Footer({}){

    return(
        <Box  component="footer" >
            <Box className="container">
                <Box sx={{width:"100%", background: "rgb(4, 162, 201)"}}>
                    <Box sx={{display:"flex", flexDirection:"column", padding:"10%"}}>
                        <span className="text titre">Adhésion</span>
                        <span className="text paragraph">Devenez acteur de notre profession, rejoignez-nous !<br/>Découvrez toutes nos offres et adhérez directement en ligne !</span>
                        <Box sx={{display:"flex", justifyContent:"center", pt:"20px", width:"100%"}}>
                            <a href="https://alize-kine.org/adhesion" className="bouton bt-transparent-blanc uppercase">J'adhère</a>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{width:"100%", background: "rgb(0, 100, 160)"}}>
                    <Box sx={{display:"flex", flexDirection:"column", padding:"10%"}}>
                        <span className="text titre">Faire un don</span>
                        <span className="text paragraph">Aidez nous à vous aider</span>
                        <Box sx={{display:"flex", justifyContent:"center", pt:"20px", width:"100%"}}>
                            <a href="https://syndicat-alize.assoconnect.com/collect/description/240778-d-don" className="bouton bt-transparent-blanc uppercase">Je fais un don</a>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{background: "rgb(21, 21, 21)", padding: "50px 5% 30px"}}>
                <Box sx={{display:"flex", justifyContent: "space-between", pb: 20}}>
                    <Box className="footer_col1">
                        Logo
                    </Box>
                    <Box className="footer_col2">
                        <span style={{color: "white",fontSize: 15,letterSpacing: "0.3px", lineHeight: 1.5, padding: "20px 0px 15px"}}>Rejoignez l'espace adhérent :</span>
                        <a href="https://alize-kine.org/login" className="boutonConnexion">Connexion</a>
                    </Box>
                    <Box className="footer_col3">
                        <span style={{color: "white", fontSize: 15, letterSpacing: 0.3, lineHeight: 1.5, padding: "0px 0px 5px"}}>
                            NOUS CONTACTER PAR MAIL :<br/>
                            Contact : <a href="mailto:contact@alize-kine.org" target="blank">contact@alize-kine.org</a><br/>
                            Questions juridiques : <a href="mailto:juridique@alize-kine.org" target="blank">juridique@alize-kine.org</a><br/>
                            Facturation : <a href="mailto:tresorier@alize-kine.org" target="blank">tresorier@alize-kine.org</a><br/>
                            Formation : <a href="mailto:formation@alize-kine.org" target="blank">formation@alize-kine.org</a><br/>
                        </span>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

