
const Colors = {
    mediumBlue : "#1AB1E6",
    darkBlue : "#0487CD",
    darkerBlue : "#214168",
    lightBlue : "#77E2FF",
    lighterBlue : "#9EE1F3",
    gray : "#96A1A6",
    lighterGray : "#EEEEEE",
    white : "#FFFFFF"
}

export {Colors}