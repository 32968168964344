import {Box, Button, FormControlLabel, Link, MenuItem, Select, Switch, TextField, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import API from '../services/API';
import SwipeableViews from 'react-swipeable-views';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import strapi_fetch from "../services/Strapi";
import { ArrowBack, ArrowForward, Download, NavigateBefore, NavigateNext, Replay, ReportSharp } from '@mui/icons-material';

export default function ToleranceZero(){
    const [pageIndex, setPageIndex] = useState(0);
    const [reponses, setReponses] = useState();
    const [motifs, setMotifs] = useState([]);

    useEffect(() => {
        if(motifs.length > 0) return

        strapi_fetch("tolerance-zero-motifs", {})
        .then(res=>{
            setMotifs(res.json.data)
        })
    }, []);

    useEffect(() => {
        console.log(reponses);
    }, [reponses]);


    return(
        <Box sx={{maxWidth:"1200px", margin:"auto"}}>
            <Box sx={{m:3}}>
                <SwipeableViews index={pageIndex} onChangeIndex={setPageIndex} disabled={true}>
                    <Page1 setPageIndex={setPageIndex} setReponses={setReponses} reponses={reponses}/>
                    <Page2 setPageIndex={setPageIndex} setReponses={setReponses} reponses={reponses} />
                    <Page3 setPageIndex={setPageIndex} setReponses={setReponses} reponses={reponses}/>
                    <Page4 setPageIndex={setPageIndex} setReponses={setReponses} motifs={motifs} reponses={reponses}/>
                    <Page5 setPageIndex={setPageIndex} setReponses={setReponses}/>
                </SwipeableViews>
            </Box>
        </Box>
    )
}


function Page1({setPageIndex, setReponses, reponses}){
    const [reponses1, setReponses1] = useState();
    const [reponses1Ok, setReponses1Ok] = useState(false);

    useEffect(() => {
        if(reponses===undefined){
            setReponses1({retour: "fichier", email:undefined, prenom:undefined, nom:undefined, departementCPAM:undefined, telephone:undefined, syndique:undefined, motifControle:undefined})
        }
    }, [reponses]);

    const handleType=((key,value)=>{
        value = value===""?undefined:value
        setReponses1(currVal => ({...currVal, [key]:value}))
    })

    useEffect(() => {
        console.log(reponses1);
        if(!reponses1) return
        setReponses1Ok(Object.values(reponses1).filter(a=>a===undefined).length===0)
    }, [reponses1]);

    const handleNext = ()=>{
        setReponses(prev=>({...prev, ...reponses1}))
        setPageIndex(1)
    }

    return(
        <Box sx={{display:"flex", flexWrap:"wrap"}}>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Adresse email</LabelQuestion>
                <TextField placeholder='Votre adresse mail' value={reponses1?.email||""} onChange={ev=>handleType("email", ev.target.value)} variant="standard" sx={{width:"100%", px:"10px", mt:1}} />
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Prénom</LabelQuestion>
                <TextField placeholder='Votre prénom' value={reponses1?.prenom||""} onChange={ev=>handleType("prenom", ev.target.value)} variant="standard" sx={{width:"100%", px:"10px", mt:1}} />
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Nom</LabelQuestion>
                <TextField placeholder='Votre nom' value={reponses1?.nom||""} onChange={ev=>handleType("nom", ev.target.value)} variant="standard" sx={{width:"100%", px:"10px", mt:1}} />
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Département d'exercice</LabelQuestion>
                <Box sx={{width:"100%", px:"10px", mt:1}}>
                    <Select value={reponses1?.departementCPAM||""} onChange={ev=>handleType("departementCPAM", ev.target.value)} variant="standard" sx={{width:"100%"}} >
                        {departements.map(d=>(
                            <MenuItem key={d.key} value={d.key}>{d.value}</MenuItem>
                        ))}
                        <MenuItem value="" sx={{display:"none"}}></MenuItem>
                    </Select>
                </Box>
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Téléphone professionnel</LabelQuestion>
                <TextField placeholder='Téléphone professionnel' value={reponses1?.telephone||""} onChange={ev=>handleType("telephone", ev.target.value)} variant="standard" sx={{width:"100%", px:"10px", mt:1}} />
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Etes-vous syndiqué ?</LabelQuestion>
                <Box sx={{width:"100%", px:"10px", mt:1}}>
                    <Select value={reponses1?.syndique||""} onChange={ev=>handleType("syndique", ev.target.value)} variant="standard" sx={{width:"100%"}} >
                        {itemsSyndique.map(i=>(
                            <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
                        ))}
                        <MenuItem value="" sx={{display:"none"}}></MenuItem>
                    </Select>
                </Box>
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Motif de contrôle</LabelQuestion>
                <Box sx={{width:"100%", px:"10px", mt:1}}>
                    <Select value={reponses1?.motifControle||""} onChange={ev=>handleType("motifControle", ev.target.value)} variant="standard" sx={{width:"100%"}} >
                        {itemsMotifsControle.map(i=>(
                            <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
                        ))}
                        <MenuItem value="" sx={{display:"none"}}></MenuItem>
                    </Select>
                </Box>
            </Box>
            <Button disabled={!reponses1Ok} sx={{...styles.buttonDegrade, background:reponses1Ok?"linear-gradient(0deg, #4c718e, #112a48)":"gray",}} onClick={handleNext}>Suivant <ArrowForward sx={styles.buttonIconRight}/></Button>
        </Box>
    )
}

function Page2({setPageIndex, setReponses, reponses}){
    const [poursuivre, setPoursuivre] = useState(false);
    const [reponses2, setReponses2] = useState();
    const [reponses2Ok, setReponses2Ok] = useState(false);
    
    useEffect(() => {
        if(reponses===undefined){
            setReponses2({typeControle:undefined, etapeControle:undefined})
        }
    }, [reponses]);

    const handleType=((key,value)=>{
        value = value===""?undefined:value
        setReponses2(currVal => ({...currVal, [key]:value}))
    })

    useEffect(() => {
        if(!reponses2) return
        setReponses2Ok(Object.values(reponses2).filter(a=>a===undefined).length===0)
    }, [reponses2]);

    const handleNext = ()=>{
        setReponses(prev=>({...prev, ...reponses2}))
        setPageIndex(2)
    }

    return(
        <Box>
            {(reponses?.motifControle == "fraude" || reponses?.motifControle == "fauteEtFraude")  &&
            <>
                <Typography>Bonjour, </Typography>
                <Typography>Votre contrôle portant sur <span style={{fontWeight:900}}>{reponses?.motifControle == "fraude" ? "une fraude" : "une faute ET une fraude"}</span>, nous vous conseillons de contacter le pôle juridique du syndicat afin d'obtenir des conseils plus spécifiques à votre situation et ne pas compromettre vos chances d'aboutir à une résolution positive.</Typography>
                <Link target="_blank" href="mailto:juridique@alize-kine.org" sx={{my:2, display:"block"}}>juridique@alize-kine.org</Link>
                {reponses?.motifControle == "fauteEtFraude"  &&
                    <>
                        <Typography>Vous pouvez tout de même poursuivre le remplissage du formulaire afin de recevoir votre courrier type <span style={{fontWeight:900}}>pour vos motifs de faute</span></Typography>
                        {!poursuivre && <Button sx={{display:"block", margin:"auto"}} onClick={()=>setPoursuivre(true)}>Poursuivre</Button>}
                    </>
                }
            </>
            }

            {(reponses?.motifControle == "faute" || poursuivre) && 
                <Box sx={{display:"flex", flexWrap:"wrap", mt:2}}>
                    <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                        <LabelQuestion>Type de contrôle</LabelQuestion>
                        <Box sx={{width:"100%", px:"10px", mt:1}}>
                            <Select value={reponses2?.typeControle||""} onChange={ev=>handleType("typeControle", ev.target.value)} variant="standard" sx={{width:"100%"}} >
                                {itemsTypeIndus.map(i=>(
                                    <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
                                ))}
                                <MenuItem value="" sx={{display:"none", fontSize:"calc(100% / 20)"}}></MenuItem>
                            </Select>
                            <Typography sx={{textAlign:"justify", fontSize:"13px"}}>Si le terme "médical" n'est pas mentionné, c'est par défaut un contrôle administratif</Typography>
                        </Box>
                    </Box>
                    <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                        <LabelQuestion>étape du contrôle</LabelQuestion>
                        <Box sx={{width:"100%", px:"10px", mt:1}}>
                            <Select value={reponses2?.etapeControle||""} onChange={ev=>handleType("etapeControle", ev.target.value)} variant="standard" sx={{width:"100%"}} >
                                {itemsEtapes.map(i=>(
                                    <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
                                ))}
                                <MenuItem value="" sx={{display:"none"}}></MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{width:"50%", my:2}}>
                        <Button sx={{...styles.buttonDegrade, background:"linear-gradient(0deg, #4c718e, #112a48)",}} onClick={()=>setPageIndex(0)}><ArrowBack sx={styles.buttonIconLeft}/>Précédent</Button>
                    </Box>
                    <Box sx={{width:"50%", my:2, textAlign:'right'}}>
                        <Button disabled={!reponses2Ok} sx={{...styles.buttonDegrade, background:reponses2Ok?"linear-gradient(0deg, #4c718e, #112a48)":"gray",}} onClick={handleNext}>Suivant <ArrowForward sx={styles.buttonIconRight}/></Button>
                    </Box>
                </Box>
            }
        </Box>
    )
}

function Page3({setPageIndex, setReponses, reponses}){
    const [reponses3, setReponses3] = useState();
    const [reponses3Ok, setReponses3Ok] = useState(false);
    
    useEffect(() => {
        if(reponses===undefined){
            setReponses3({dateCourrier:dayjs().format("DD/MM/YYYY"), montant:undefined})
        }
    }, [reponses]);

    const handleType=((key,value)=>{
        value = value===""?undefined:value
        setReponses3(currVal => ({...currVal, [key]:value}))
    })

    useEffect(() => {
        if(!reponses3) return
        setReponses3Ok(Object.values(reponses3).filter(a=>a===undefined).length===0)
    }, [reponses3]);

    const handleNext = ()=>{
        setReponses(prev=>({...prev, ...reponses3}))
        setPageIndex(3)
    }

    return(
        <Box sx={{display:"flex", flexWrap:"wrap"}}>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion style={{width:"315px"}}>Date de réception du courrier</LabelQuestion>
                <Box sx={{width:"100%", px:"10px", mt:1}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-FR">
                        <DatePicker format='DD/MM/YYYY' value={reponses3?dayjs(reponses3.dateCourrier, 'DD/MM/YYYY'):dayjs()} maxDate={dayjs()} onChange={(value, context)=>handleType("dateCourrier",value.format("DD/MM/YYYY"))} sx={{"& > .MuiInputBase-root > .MuiInputBase-input":{p:"4.5px 0 4.5px 14px"}, "& > .MuiInputBase-root > fieldset":{border: 0, borderBottom: "1px solid black", borderRadius: 0, height: "32px", top: 0}}}/>
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <LabelQuestion>Montant de l'indu</LabelQuestion>
                <TextField placeholder='Montant' value={reponses3?.montant||""} onChange={ev=>handleType("montant", ev.target.value)} variant="standard" sx={{width:"100%", px:"10px", mt:1}} />
            </Box>
            <Box sx={{width:"50%", my:2}}>
                <Button sx={{...styles.buttonDegrade, background:"linear-gradient(0deg, #4c718e, #112a48)",}} onClick={()=>setPageIndex(1)}><ArrowBack sx={styles.buttonIconLeft}/>Précédent</Button>
            </Box>
            <Box sx={{width:"50%", my:2, textAlign:'right'}}>
                <Button disabled={!reponses3Ok} sx={{...styles.buttonDegrade, background:reponses3Ok?"linear-gradient(0deg, #4c718e, #112a48)":"gray",}} onClick={handleNext}>Suivant <ArrowForward sx={styles.buttonIconRight}/></Button>
            </Box>
        </Box>
    )
}


function Page4({setPageIndex, setReponses, motifs, reponses}){
    const [reponses4, setReponses4] = useState();
    const [reponses4Ok, setReponses4Ok] = useState(false);
    const [waitingFile, setWaitingFile] = useState(false);
    
    let autreIsSelected = reponses4?.motifs.indexOf("autre") >=0

    useEffect(() => {
        if(reponses===undefined){
            setReponses4({motifs:[], autreMotif:undefined})
        }
    }, [reponses]);

    const handleSelect=((key,value)=>{
        setReponses4(currVal => {
            let tmpMotifs = currVal?.motifs
            if(value){
                tmpMotifs.push(key)
            }else{
                tmpMotifs = tmpMotifs.filter(el=>el !== key)
            }
            return {...currVal, motifs:tmpMotifs}
        })
    })
    const handleType=((key,value)=>{
        value = value===""?undefined:value
        setReponses4(currVal => ({...currVal, [key]:value}))
    })

    useEffect(() => {
        if(!reponses4 && !reponses) return
        setReponses(prev=>({...prev, ...reponses4}))
        setReponses4Ok(reponses4.motifs.length>0 && (!autreIsSelected || reponses4.autreMotif !== undefined))
    }, [reponses4]);

    
    const handleSend = ()=>{
        setWaitingFile(true)
        API("GET", "docx", {}, reponses,"application/json", {}, "", "blob")
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob)
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = 'Courrier.docx';
            link.click();
            setWaitingFile(false)
            setPageIndex(4)
        })
    }

    return(
        <Box sx={{display:"flex", flexWrap:"wrap"}}>
            <Box sx={{width:"100%"}}>
                <LabelQuestion>Motifs</LabelQuestion>
            </Box>
            {motifs.map((motif, index)=>{
                let isSelected = reponses4?.motifs.indexOf(motif.attributes.Identifiant) >=0
                return(
                    <Box sx={{width:{xs:"100%",sm:"calc(50% - 25px)"}, my:2, mr:{xs:0,sm:index%2==0?"25px":0}, ml:{xs:0,sm:index%2==0?0:"25px"}}}>
                        <FormControlLabel control={<Switch checked={isSelected} />} label={motif.attributes.Titre} labelPlacement="start" onChange={ev=>handleSelect(motif.attributes.Identifiant, ev.target.checked)} sx={{justifyContent:"space-between", width:"100%", ml:0}}/>
                    </Box>
                )
            })}
            <Box sx={{width:{xs:"100%",sm:"calc(50% - 25px)"}, my:2, mr:{xs:0,sm:motifs?.length%2==0?"25px":0}, ml:{xs:0,sm:motifs?.length%2==0?0:"25px"}}}>
                <FormControlLabel control={<Switch checked={reponses4?.motifs.indexOf("autre")>=0}/>} label="Autre" labelPlacement="start" onChange={ev=>handleSelect("autre", ev.target.checked)}  sx={{justifyContent:"space-between", width:"100%", ml:0}}/>
                {autreIsSelected &&
                    <TextField placeholder='Autre' value={reponses4?.autreMotif||""} onChange={ev=>handleType("autreMotif", ev.target.value)} variant="standard" sx={{width:"100%", px:"10px"}} />
                }
            </Box>
            {motifs?.length%2==0 && <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}></Box>}
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2}}>
                <Button sx={{...styles.buttonDegrade, background:"linear-gradient(0deg, #4c718e, #112a48)",}} onClick={()=>setPageIndex(2)}><ArrowBack sx={styles.buttonIconLeft}/>Précédent</Button>
            </Box>
            <Box sx={{width:{xs:"100%",sm:"50%"}, my:2, textAlign:{xs:"right",sm:'center'}}}>
                <Button disabled={!reponses4Ok} sx={{...styles.buttonDegrade, background:reponses4Ok?"linear-gradient(0deg, #4c718e, #112a48)":"gray", maxWidth:"75%"}} onClick={handleSend}>Télécharger mon courrier type <Download sx={styles.buttonIconRight}/></Button>
            </Box>
        </Box>
    )
}

function Page5({setPageIndex, setReponses}){

    const handleRestart = ()=>{
        setReponses(undefined)
        setPageIndex(0)
    }

    return(
        <Box sx={{margin:"auto"}}>
            <Typography sx={{textAlign:"center"}}>Votre courrier type a été téléchargé. N'oubliez pas de le relire et le modifier si nécessaire avant de l'envoyer.</Typography>
            <Button sx={{...styles.buttonDegrade, background:"linear-gradient(0deg, #4c718e, #112a48)", margin:"auto", display:"block"}} variant='' onClick={handleRestart}><Replay sx={styles.buttonIconLeft}/>Recommencer</Button>
        </Box>
    )
}

function LabelQuestion({children, style}){
    return(
        <Box sx={{...styles.labelQuestion,...style}}>
            <span style={styles.labelQuestionText}>{children}</span>
        </Box>
        
    )
}

const styles = {
    buttonIconLeft:{
        verticalAlign:"middle", 
        mr:1,
    },
    buttonIconRight:{
        verticalAlign:"middle", 
        ml:1,
    },
    button: {
        width: '50%', // Définit la largeur du bouton à 50%
        backgroundColor: '#214168',
        padding: 10,
        alignItems: 'center', // Centrer le contenu horizontalement
        borderRadius: 5, // Ajoute des coins arrondis
    },
    buttonDegrade: {
        px: "20px",
        alignItems: 'center', // Centrer le contenu horizontalement
        borderRadius: "5px", // Ajoute des coins arrondis
        height: "fit-content",
        ml: "auto",
        alignSelf: "center",
        color:"white !important" ,
        fontSize: 16,
        fontWeight: 'bold',
    },
    buttonText: {
        color:"#fff",
    },
    buttonLink:{
        color:"#1ab1e6",
        textDecorationLine:"underline",
        marginBottom:10
    },
    labelQuestion:{
        width:"270px",
        height:"30px",
        borderRadius:15,
        paddingLeft:"10px",
        background:"linear-gradient(0deg, #4c718e, #112a48)",
        alignContent: "center"
    },
    labelQuestionText:{
        color:"#1ab1e6",
        textTransform:"uppercase",
        fontWeight:"600"
    },
    listeDerouante:{
        marginTop:4,
        marginBottom:12
    }
  }

const itemsSyndique =[
    {label: 'Adhérent à jour Alizé', value: 'alize'},
    {label: 'Ancien Adhérent', value: 'ancien'},
    {label: 'Adhérent à aucun syndicat', value: 'non'},
    {label: 'Adhérent FFMKR', value: 'FFMKR'},
    {label: 'Adhérent SNMKR', value: 'SNMKR'}
]
const itemsTypeIndus = [
    {label: 'Administratif', value: 'administratif'},
    {label: 'Médical', value: 'medical'},
]
const itemsMotifsControle = [
    {label: 'Faute', value: 'faute'},
    {label: 'Fraude', value: 'fraude'},
    {label: 'Faute ET fraude', value: 'fauteEtFraude'},
]
const itemsEtapes = [
    {label: 'Griefs', value: 'griefs'},
    {label: 'Indus définitifs', value: 'definitif'},
]

  const departements = [
    { key: '1', value: '01 - Ain' },
    { key: '2', value: '02 - Aisne' },
    { key: '3', value: '03 - Allier' },
    { key: '4', value: '04 - Alpes-de-Haute-Provence' },
    { key: '5', value: '05 - Hautes-Alpes' },
    { key: '6', value: '06 - Alpes-Maritimes' },
    { key: '7', value: '07 - Ardèche' },
    { key: '8', value: '08 - Ardennes' },
    { key: '9', value: '09 - Ariège' },
    { key: '10', value: '10 - Aube' },
    { key: '11', value: '11 - Aude' },
    { key: '12', value: '12 - Aveyron' },
    { key: '13', value: '13 - Bouches-du-Rhône' },
    { key: '14', value: '14 - Calvados' },
    { key: '15', value: '15 - Cantal' },
    { key: '16', value: '16 - Charente' },
    { key: '17', value: '17 - Charente-Maritime' },
    { key: '18', value: '18 - Cher' },
    { key: '19', value: '19 - Corrèze' },
    { key: '20', value: '20 - Corse' },
    { key: '2A', value: '2A - Corse-du-Sud' },
    { key: '2B', value: '2B - Haute-Corse' },
    { key: '21', value: '21 - Côte-d\'Or' },
    { key: '22', value: '22 - Côtes-d\'Armor' },
    { key: '23', value: '23 - Creuse' },
    { key: '24', value: '24 - Dordogne' },
    { key: '25', value: '25 - Doubs' },
    { key: '26', value: '26 - Drôme' },
    { key: '27', value: '27 - Eure' },
    { key: '28', value: '28 - Eure-et-Loir' },
    { key: '29', value: '29 - Finistère' },
    { key: '30', value: '30 - Gard' },
    { key: '31', value: '31 - Haute-Garonne' },
    { key: '32', value: '32 - Gers' },
    { key: '33', value: '33 - Gironde' },
    { key: '34', value: '34 - Hérault' },
    { key: '35', value: '35 - Ille-et-Vilaine' },
    { key: '36', value: '36 - Indre' },
    { key: '37', value: '37 - Indre-et-Loire' },
    { key: '38', value: '38 - Isère' },
    { key: '39', value: '39 - Jura' },
    { key: '40', value: '40 - Landes' },
    { key: '41', value: '41 - Loir-et-Cher' },
    { key: '42', value: '42 - Loire' },
    { key: '43', value: '43 - Haute-Loire' },
    { key: '44', value: '44 - Loire-Atlantique' },
    { key: '45', value: '45 - Loiret' },
    { key: '46', value: '46 - Lot' },
    { key: '47', value: '47 - Lot-et-Garonne' },
    { key: '48', value: '48 - Lozère' },
    { key: '49', value: '49 - Maine-et-Loire' },
    { key: '50', value: '50 - Manche' },
    { key: '51', value: '51 - Marne' },
    { key: '52', value: '52 - Haute-Marne' },
    { key: '53', value: '53 - Mayenne' },
    { key: '54', value: '54 - Meurthe-et-Moselle' },
    { key: '55', value: '55 - Meuse' },
    { key: '56', value: '56 - Morbihan' },
    { key: '57', value: '57 - Moselle' },
    { key: '58', value: '58 - Nièvre' },
    { key: '59', value: '59 - Nord' },
    { key: '60', value: '60 - Oise' },
    { key: '61', value: '61 - Orne' },
    { key: '62', value: '62 - Pas-de-Calais' },
    { key: '63', value: '63 - Puy-de-Dôme' },
    { key: '64', value: '64 - Pyrénées-Atlantiques' },
    { key: '65', value: '65 - Hautes-Pyrénées' },
    { key: '66', value: '66 - Pyrénées-Orientales' },
    { key: '67', value: '67 - Bas-Rhin' },
    { key: '68', value: '68 - Haut-Rhin' },
    { key: '69', value: '69 - Rhône' },
    { key: '70', value: '70 - Haute-Saône' },
    { key: '71', value: '71 - Saône-et-Loire' },
    { key: '72', value: '72 - Sarthe' },
    { key: '73', value: '73 - Savoie' },
    { key: '74', value: '74 - Haute-Savoie' },
    { key: '75', value: '75 - Paris' },
    { key: '76', value: '76 - Seine-Maritime' },
    { key: '77', value: '77 - Seine-et-Marne' },
    { key: '78', value: '78 - Yvelines' },
    { key: '79', value: '79 - Deux-Sèvres' },
    { key: '80', value: '80 - Somme' },
    { key: '81', value: '81 - Tarn' },
    { key: '82', value: '82 - Tarn-et-Garonne' },
    { key: '83', value: '83 - Var' },
    { key: '84', value: '84 - Vaucluse' },
    { key: '85', value: '85 - Vendée' },
    { key: '86', value: '86 - Vienne' },
    { key: '87', value: '87 - Haute-Vienne' },
    { key: '88', value: '88 - Vosges' },
    { key: '89', value: '89 - Yonne' },
    { key: '90', value: '90 - Territoire de Belfort' },
    { key: '91', value: '91 - Essonne' },
    { key: '92', value: '92 - Hauts-de-Seine' },
    { key: '93', value: '93 - Seine-Saint-Denis' },
    { key: '94', value: '94 - Val-de-Marne' },
    { key: '95', value: '95 - Val-d\'Oise' },
    { key: '971', value: '971 - Guadeloupe' },
    { key: '972', value: '972 - Martinique' },
    { key: '973', value: '973 - Guyane' },
    { key: '974', value: '974 - La Réunion' },
    { key: '976', value: '976 - Mayotte' }
];