import logo from './logo.svg';
import './App.css';
import {Button, Box, CssBaseline } from '@mui/material';
import API from './services/API';
import React, { useState } from 'react';
import ResponsiveAppBar from './scenes/Appbar';
import {BrowserRouter as Router, Redirect, Route, Routes, useLocation, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import Accueil from './scenes/Accueil';
import ToleranceZero from './scenes/ToleranceZero';
import Cotations from './scenes/Cotations';
import Footer from './scenes/Footer';
import { Colors } from './services/Colors';

export default function App() {
    

    const router = createBrowserRouter(
        createRoutesFromElements(
            <React.Fragment>
                <Route path="/*" element={<App2/>}/>
            </React.Fragment>
        )
      );

    return (
        <RouterProvider router={router} />
    )
}

function App2(){
    return(
        <Box sx={{ display: 'flex', flexDirection:"column" }}>
            <CssBaseline />
            <ResponsiveAppBar/>
            <Box component="main" sx={{ mt : {xs:"56px", sm:"80px"}, backgroundColor:Colors.lighterGray/*p: 3, , width:"100%", maxWidth:"1200px", mx:"auto"*/ }}>
                <Routes>
                    <Route path="/" element={<Accueil/>}/>
                    <Route path="accueil" element={<Accueil/>}/>
                    <Route path="cotations" element={<Cotations/>}/>
                    <Route path="tolerance_zero" element={<ToleranceZero/>}/>
                </Routes>
            </Box>
            <Footer/>
        </Box>

  );
}


