import * as React from 'react';
import {AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, CssBaseline, Link   } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {BrowserRouter as Router, Redirect, Route, Routes, NavLink, useMatch, useResolvedPath, resolvePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import "../css/Styles.css"
import { Colors } from '../services/Colors';

const drawerWidth = 240;
const navItems = [{title:'Accueil', id:"accueil"},{title:'Cotations', id:"cotations"},{title:'Tolérance zéro', id:"tolerance_zero"}];

export default function ResponsiveAppBar(props) {
  /*const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  

  return (
    <AppBar position="static" sx={{backgroundColor:"#E29E21"}}>
      <Container maxWidth="xl">
        <Toolbar>
            <Box sx={{height:"120px", width:"20%", 
                backgroundImage: 'url("logo.png")',
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"}}>
            </Box>
          

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>




            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                    <Button
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                    {page}
                    </Button>
                ))}
            </Box>



          {/*<Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            </Box>*}
        </Toolbar>
      </Container>
    </AppBar>
  );*/

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box sx={{height:"120px", width:"100%", 
                backgroundImage: 'url("logo.png")',
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"}}>
            </Box> 
            <Divider />
            <List>
                {navItems.map((item) => (
                <ListItem key={item.id} disablePadding onClick={()=>navigate(item.id)}>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
    <>
      <AppBar component="nav" sx={{backgroundColor:"white"}}>
        <Toolbar sx={{px:{sm:1, md:2, lg:4}}}>
          <IconButton
            color={Colors.darkBlue}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

        <Box sx={{display:{ xs: 'none', sm: 'flex' }, justifyContent: "space-between", alignItems: "center", width:"100%"}}>
            <Box sx={{height:"70px", width:"20%", marginY:"5px", flex:2,
                backgroundImage: 'url("logo_horiz.png")',
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundPositionX : "left"}}>
            </Box>  
            <Box sx={{ flex:3, textAlign:"center", display:"flex", alignItems:"center", justifyContent: "center"}}>
                {navItems.map((item) => (
                    <CustomLink to={item.id}>{item.title}</CustomLink>
                ))}
            </Box>
            <Box sx={{flex:2, textAlign:"right"}}>
                <Link href="https://www.alize-kine.org/contact" sx={styles.bouton} className="bt-blanc-bleu uppercase">Contact</Link>
                <Link href="https://alize-kine.org/login" sx={styles.bouton} className="bt-blanc-bleu uppercase">Mon espace</Link>
            </Box>
        </Box>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );

}


function CustomLink({children, to,  root="", ...props  }) {
    const navigate = useNavigate();
    let resolved = useResolvedPath(root===""?to:root);
    console.log(resolved);
    let match = useMatch({ path: resolved.pathname, end: false });
  
    const handleNavigation = () => {
        navigate(to);
    };

    /*let style = match ? {
        fontWeight:"bolder",
        paddingBottom: "4px",
        backgroundImage: "linear-gradient(to right, transparent 10%, #214168 10%, #214168 90%, transparent 90%)",
        backgroundPosition: "0px 100%",
        backgroundSize: "100% 1px",
        backgroundRepeat: "no-repeat"
    }: {}*/

  
    return (
        <Link
            sx={{textAlign:"center", cursor:match?"default":"pointer", color : match ? "rgb(4, 162, 201)" : "black", fontSize:{sm:"14px",md:"18px",lg:"23px"},  textDecoration:"none", textAlign:"center", position:"relative", marginX:"15px"}} 
            onClick={handleNavigation}
        >
            {children}
        </Link>
    );
  }




const styles={
    bouton:{
        fontSize:{sm:"8px", md:"12px", lg:"18px"},
        px:{sm:"8px", md:"15px", lg:"20px"},
        py:"10px",
        mx:{sm:"5px", md:"10px"},
        borderStyle: "solid",
        borderWidth: "1px",
        textAlign: "center",
        cursor: "pointer",
        textDecoration: "none",
        fontFamily: "Open Sans",
    }
}