
import {Button, Box, CssBaseline, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Accueil(){
    const navigate = useNavigate();

    return(
        <Box>
            <Box sx={{width:"100%", backgroundImage:'url("outils_numeriques.png")', height:"70.5vw", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionY:"center", maxHeight:"70vh", position:"relative"}}>
                <Box sx={{color: "white", fontSize: "28px", fontWeight: "bold", position:"absolute", "bottom":0, margin:"20px"}}>Vos Outils Numériques !</Box>
            </Box>
            <Box sx={{display:'flex', width:"100%", height:"230px"}}>
                <Box sx={{flex:1, backgroundColor:"rgb(0, 100, 160)", padding:2}}>
                    <Link className='bouton bt-transparent-blanc' sx={{display:"block", margin:"auto"}} onClick={()=>navigate('/cotations')} >Cotations by Alizé !</Link>
                    <Box sx={{background:'white url("logo_cotations.png") no-repeat center', backgroundSize:"contain", height:{xs:"calc(35vw - 32px)",sm:"140px"}, width:{xs:"calc(50vw - 32px)",sm:"200px"}, margin:"10px auto" }} onClick={()=>navigate('/cotations')}></Box> 
                </Box>
                <Box sx={{flex:1, backgroundColor:"rgb(4, 162, 201)", padding:2}}>
                    <Link className='bouton bt-transparent-blanc' sx={{display:"block", margin:"auto"}} onClick={()=>navigate('/tolerance_zero')} >Tolérance Zéro by Alizé !</Link>
                    <Box sx={{background:'white url("logo_tolerance0.png") no-repeat center', backgroundSize:"contain", height:{xs:"calc(35vw - 32px)",sm:"140px"}, width:{xs:"calc(50vw - 32px)",sm:"200px"}, margin:"10px auto" }} onClick={()=>navigate('/tolerance_zero')}></Box> 
                </Box>
            </Box>
        </Box>
    )
}