export default function strapi_fetch(path, queries){
    let uri = "https://strapi.alize-kine.org/api/"
    let bearer = "47ee8275c70eb681376d9816c961c033fe1c090653e018ecf9754f5d65c8698ef0796abae5a0a3d4f0fd994ddf8e10c83893b62f58c200e16163d6378f6ccedd319382d1f3c113e6a02153f89aec3f489165a58c79ec00ab85239152278c3b653b9f27d25aaad755207012dac7b3f361d0d3287f9bdf9b85582e050615f21fd9"

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${bearer}`);

    var myInit = {
        method: "GET",
        headers: myHeaders
    }
    const url = new URL(uri+path);
    Object.keys(queries).forEach(key => {
        if(queries[key]){
            if(typeof(queries[key])!="string") queries[key] = JSON.stringify(queries[key])
            url.searchParams.append(key, queries[key])
        }
    })

    let tmpResponse
    return fetch(url, myInit)
    .then(async response => {
        //console.log("coucou",response)
        tmpResponse = response
        if(response.status === 204){
            tmpResponse.json = []
            return tmpResponse
        }
        tmpResponse.json = await response.json()
        return tmpResponse
    })
    .catch(err => {
        if(err.name === "SyntaxError" && 
            (err.message.includes("nexpected") && err.message.includes("JSON") ||   //U-unexpected JSON (chrome ou firefox)
            err.message.includes("string did not match the expected pattern"))){    // idem (safari)
            return tmpResponse
        }
        throw new Error(err)
    });
}